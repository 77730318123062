import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import 'moment/locale/it';

import Styles from './BlogRoll.styles';
const useStyles = createUseStyles(Styles);

const BlogRoll = ({ blogs, ...props }) => {
  const classes = useStyles(props);
  const [selected, handleSelected] = useState('TUTTE');

  const onSelect = sel => {
    handleSelected(sel);
  };

  const renderCategories = () => {
    let categories = [];
    blogs.forEach(el => {
      categories.push(el.node.frontmatter.category);
    });
    categories = [...new Set(categories.flat())];

    categories.unshift('TUTTE');

    return categories.map(el => {
      return (
        <div
          className={`${classes.category} ${
            selected === el ? classes.categoryActive : ''
          }`}
          key={el}
        >
          <a
            className={`${classes.categoryLink} ${
              selected === el ? classes.categoryLinkActive : ''
            }`}
            href={`#${el}`}
            onClick={e => {
              e.preventDefault();
              onSelect(el);
            }}
          >
            {el}
          </a>
        </div>
      );
    });
  };

  const renderBlogs = () => {
    const filteredBlogs =
      selected && selected !== 'TUTTE'
        ? blogs.filter(e => {
            return e.node.frontmatter.category === selected;
          })
        : blogs;

    return filteredBlogs.map(blog => {
      const data = blog.node.frontmatter;
      const image = data.image ? data.image.replace('static/', '') : null;
      return (
        <a
          href={`/blog/${data.category}/${data.path}`}
          key={data.path}
          className={classes.blogSingle}
        >
          {image && (
            <img
              src={`/${image}`}
              alt={data.title}
              className={classes.blogImage}
            />
          )}

          <h3 className={classes.blogTitle}>{data.title}</h3>
          <p className={classes.blogDate}>
            <span>Liveflow</span> |{' '}
            {moment(data.date).locale('it').format('DD MMMM YYYY')}
          </p>
          <div className={classes.description}>{data.description}</div>
          <div className={classes.link}>
            <span className={classes.linkA}>LEGGI</span>
          </div>

          <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "Article",
                "headline": "${data.title}",
                "author": "Liveflow",
                "datePublished": "${data.date}",
                "dateModified": "${data.date}",
                "description": "${data.description}",
                "image": "https://liveflow.it/${image}",
                "publisher": {
                  "@type": "Organization",
                  "name": "Liveflow",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.liveflow.it/logo.png"
                  }
                }
              }`}
          </script>
        </a>
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.categories}>{renderCategories()}</div>
        <div className={classes.blog}>{renderBlogs()}</div>
      </div>
    </div>
  );
};

BlogRoll.propTypes = {
  blogs: PropTypes.array,
};

export default BlogRoll;
