import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';
import BlogRoll from '@components/BlogRoll/BlogRoll';

const Blog = ({ data, location }) => {
  const title = 'Blog';
  const subtitle = 'Tutto quello che devi sapere per far pace con corpo e cibo';

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <div className="page_container">
        <PageHeader title={title} subtitle={subtitle} />
        <BlogRoll blogs={data.allMarkdownRemark.edges} />
      </div>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogPosts {
    allMarkdownRemark(
      filter: { frontmatter: { title: { ne: "" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            path
            date(formatString: "YYYY-MM-DD")
            title
            category
            image
            description
          }
          html
        }
      }
    }
  }
`;
