import Colors from '@utils/colors.js';
import Breakpoints from '@utils/breakpoints';
import Dimensions from '@utils/dimensions';
import Utils from '@utils/';

const Styles = {
  container: {},
  content: {
    maxWidth: Dimensions.contentMidWidth,
    margin: '0 auto',
  },
  categories: {},
  category: {
    display: 'inline-block',
    borderRadius: '16px',
    border: '2px solid ' + Colors.accent,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: Colors.accent,
    margin: '0 10px 10px 0',
    transition: Utils.transition('background', '.2s'),
    '&:hover': {
      background: Colors.accent,
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '12px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  categoryActive: {
    background: Colors.accent,
    color: `${Colors.white} !important`,
  },
  categoryLink: {
    padding: '5px 15px',
    display: 'inline-block',
    textDecoration: 'none !important',
    transition: Utils.transition('color', '.2s'),
    '&:hover': {
      color: Colors.white,
    },
  },
  categoryLinkActive: {
    color: `${Colors.white} !important`,
  },
  blog: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginTop: '20px',
    },
  },
  blogSingle: {
    width: '50%',
    marginBottom: '80px',
    color: Colors.base + ' !important',
    textDecoration: 'none !important',
    transition: Utils.transition('opacity', '.2s'),
    '&:nth-child(odd)': {
      paddingRight: '20px',
    },
    '&:nth-child(even)': {
      paddingLeft: '20px',
    },
    '&:hover': {
      opacity: '0.7',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      marginBottom: '40px',
      '&:nth-child(odd)': {
        paddingRight: '0',
      },
      '&:nth-child(even)': {
        paddingLeft: '0',
      },
    },
  },
  blogImage: {
    marginBottom: '20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: 'calc(100% + 40px)',
      maxWidth: 'calc(100% + 40px)',
      marginRight: '-20px',
      marginLeft: '-20px',
      marginBottom: '15px',
    },
  },
  blogTitle: {
    fontSize: '32px',
    margin: 0,
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '28px',
    },
  },
  blogDate: {
    fontSize: '12px',
    margin: '5px 0 0 0',
  },
  description: {
    margin: '20px 0',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '14px',
    },
  },
  link: {
    textAlign: 'right',
  },
  linkA: {
    fontSize: '12px',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
};

export default Styles;
